body
{
  font-family: 'Titillium Web', sans-serif;
}
.navbar
{
  font-family: 'Poppins';
  font-family: 'Titillium Web', sans-serif;
}
footer {
  box-shadow: 0 -0.2rem 1rem rgba(0,0,0,.15)!important;
}
.shadow
{
  
}

.tagline
{
  font-size: 0.73rem;
  line-height: 0.75rem;
  font-weight: 500;
  text-align: right;
  vertical-align: text-top;
  margin: 0.1rem  0.9rem 0 0;
}
.b1
{
  line-height: 2.6rem;
  font-size: 2.6rem;
  vertical-align: text-top;
  display:inline-block;
  z-index: 2000 !important;
}
.b2
{
  line-height: 2rem;
  font-size: 2rem;
  vertical-align: text-top;
  display:inline-block;
  margin-left: 0.3rem;
  padding-right:10px;
  margin-top: 0.1rem;
  z-index: 1;
}
.bar
{
  position: relative;
  top: 0px;
  height: 1px;
  margin: 0 0 0;
  margin-right:-1.2rem;
  margin-left:-0.0rem;
  background-color: red;
  z-index: 1;
  box-shadow: 0 0rem 0.1rem rgba(255, 0, 0, 1.75)!important;
}
.navbar-brand
{
  font-weight: 700;
  font-size: 200%;
}
.logosquare .b1
{
  text-align: right;
  display:block;
}
.logosquare .b2
{
  padding-right: 0;
}
.logosquare .bar
{
  padding-right: 0;
}
.logosquare .tagline
{
  margin-right: 0;
}
.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #09d3ac;
}
